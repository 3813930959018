const ICONS = {
    logo: `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="70px" height="83px" viewBox="0 0 70 83" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
        <title>8A715B74-386E-4810-BEC0-336D0280CE6C</title>
        <desc>Created with sketchtool.</desc>
        <defs>
            <linearGradient x1="40.7107904%" y1="12.0200944%" x2="172.239319%" y2="258.339661%" id="linearGradient-1">
                <stop stop-color="#E2D181" offset="0%"></stop>
                <stop stop-color="#DBCB7D" stop-opacity="0.97" offset="10%"></stop>
                <stop stop-color="#C9BA73" stop-opacity="0.9" offset="23%"></stop>
                <stop stop-color="#AB9E61" stop-opacity="0.78" offset="40%"></stop>
                <stop stop-color="#807749" stop-opacity="0.61" offset="58%"></stop>
                <stop stop-color="#4A452A" stop-opacity="0.4" offset="77%"></stop>
                <stop stop-color="#090805" stop-opacity="0.14" offset="97%"></stop>
                <stop stop-color="#000000" stop-opacity="0.1" offset="100%"></stop>
            </linearGradient>
            <radialGradient cx="70.9187094%" cy="54.4439516%" fx="70.9187094%" fy="54.4439516%" r="53.4354521%" id="radialGradient-2">
                <stop stop-color="#E8D781" offset="0%"></stop>
                <stop stop-color="#E1D079" offset="64%"></stop>
                <stop stop-color="#D1C067" offset="100%"></stop>
            </radialGradient>
        </defs>
        <g id="Map" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Map-of-Areas-v5" transform="translate(-308.000000, -863.000000)">
                <g id="Group-24" transform="translate(308.000000, 863.000000)">
                    <path d="M52.9857143,55.8571429 L35.3142857,83 L16.9714286,55.7714286 L24.2428571,48.4571429 C30.4935853,53.4688977 39.3741973,53.5103405 45.6714286,48.5571429 L52.9857143,55.8571429 Z" id="Path" fill="#E2D181"></path>
                    <path d="M66.9571429,52.3571429 L69.9857143,44.9142857 L61.9571429,40.3428571 C62.7002994,36.7090353 62.7002994,32.9623933 61.9571429,29.3285714 L69.9285714,24.6714286 L66.8,17.2571429 L57.8428571,19.7142857 C55.7805914,16.6282697 53.1122244,13.9939256 50,11.9714286 L52.3428571,3.02857143 L44.9,5.07530526e-14 L40.3285714,8.02857143 C36.6899317,7.30896461 32.9432864,7.33326192 29.3142857,8.1 L24.6571429,0.128571429 L17.2714286,3.24285714 L19.7142857,12.1571429 C16.6289241,14.2143148 13.9945096,16.8778925 11.9714286,19.9857143 L2.98571429,17.6428571 L-1.52259158e-13,25.1 L8.02857143,29.6714286 C7.28541493,33.3052504 7.28541493,37.0518924 8.02857143,40.6857143 L0.128571429,45.3285714 L3.25714286,52.7428571 L12.1571429,50.2857143 C12.596689,50.9823945 13.0735752,51.6548042 13.5857143,52.3 L20.9142857,44.9714286 C16.2322293,38.2543665 16.9538577,29.1639602 22.6370431,23.2697275 C28.3202284,17.3754948 37.3782945,16.3230338 44.2615376,20.7571672 C51.1447807,25.1913005 53.9314832,33.8740723 50.9142857,41.4857143 C50.4039786,42.7677623 49.7370073,43.9817461 48.9285714,45.1 L56.2571429,52.4142857 C56.8952942,51.6505965 57.4868426,50.8491438 58.0285714,50.0142857 L66.9571429,52.3571429 Z" id="Path" fill="#FFFFFF"></path>
                    <path d="M35.3142857,83 L16.9714286,55.7714286 L24.2428571,48.4571429 C27.3269872,50.845175 31.1000414,52.1730095 35,52.2428571 L35.0714286,60.4714286 L35.3142857,83 Z" id="Path" fill="url(#linearGradient-1)"></path>
                    <circle id="Oval" fill="url(#radialGradient-2)" fill-rule="nonzero" cx="34.9428571" cy="35.1" r="7.02857143"></circle>
                </g>
            </g>
        </g>`,
    shareDevice: `<?xml version="1.0" encoding="UTF-8"?>
        <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
            <title>8ABBF32A-86BB-4252-9D7F-0516D5EB9BE9</title>
            <desc>Created with sketchtool.</desc>
            <g id="MOBILE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="home-mobile-v5.1" transform="translate(-238.000000, -596.000000)" fill-rule="nonzero">
                    <g id="Group-33" transform="translate(238.000000, 596.000000)">
                        <g id="linkedin-copy" fill="#0292F2">
                            <circle id="Oval" cx="23.8292389" cy="23.8288142" r="23.8292389"></circle>
                        </g>
                        <g id="share-(4)" transform="translate(12.000000, 11.000000)" fill="#FFFFFF">
                            <path d="M23.1488697,4.3332672 C23.1488697,6.27783203 21.5638963,7.8542328 19.6085106,7.8542328 C17.653125,7.8542328 16.0681516,6.27783203 16.0681516,4.3332672 C16.0681516,2.38890077 17.653125,0.8125 19.6085106,0.8125 C21.5638963,0.8125 23.1488697,2.38890077 23.1488697,4.3332672 L23.1488697,4.3332672 Z" id="Path"></path>
                            <path d="M19.6085106,8.6667328 C17.2053191,8.6667328 15.2511303,6.72315978 15.2511303,4.3332672 C15.2511303,1.94357302 17.2053191,0 19.6085106,0 C22.0117021,0 23.9658909,1.94357302 23.9658909,4.3332672 C23.9658909,6.72315978 22.0117021,8.6667328 19.6085106,8.6667328 Z M19.6085106,1.625 C18.1063165,1.625 16.8851729,2.84057617 16.8851729,4.3332672 C16.8851729,5.82615663 18.1063165,7.0417328 19.6085106,7.0417328 C21.1107048,7.0417328 22.3318484,5.82615663 22.3318484,4.3332672 C22.3318484,2.84057617 21.1107048,1.625 19.6085106,1.625 Z" id="Shape"></path>
                            <path d="M23.1488697,21.6667328 C23.1488697,23.6110992 21.5638963,25.1875 19.6085106,25.1875 C17.653125,25.1875 16.0681516,23.6110992 16.0681516,21.6667328 C16.0681516,19.722168 17.653125,18.1457672 19.6085106,18.1457672 C21.5638963,18.1457672 23.1488697,19.722168 23.1488697,21.6667328 L23.1488697,21.6667328 Z" id="Path"></path>
                            <path d="M19.6085106,26 C17.2053191,26 15.2511303,24.056427 15.2511303,21.6667328 C15.2511303,19.2768402 17.2053191,17.3332672 19.6085106,17.3332672 C22.0117021,17.3332672 23.9658909,19.2768402 23.9658909,21.6667328 C23.9658909,24.056427 22.0117021,26 19.6085106,26 Z M19.6085106,18.9582672 C18.1063165,18.9582672 16.8851729,20.1738434 16.8851729,21.6667328 C16.8851729,23.1594238 18.1063165,24.375 19.6085106,24.375 C21.1107048,24.375 22.3318484,23.1594238 22.3318484,21.6667328 C22.3318484,20.1738434 21.1107048,18.9582672 19.6085106,18.9582672 L19.6085106,18.9582672 Z" id="Shape"></path>
                            <path d="M7.89793884,13 C7.89793884,14.9445648 6.31276596,16.5207672 4.35738031,16.5207672 C2.40219416,16.5207672 0.817021277,14.9445648 0.817021277,13 C0.817021277,11.0554352 2.40219416,9.4792328 4.35738031,9.4792328 C6.31276596,9.4792328 7.89793884,11.0554352 7.89793884,13 Z" id="Path"></path>
                            <path d="M4.35738031,17.3332672 C1.95438832,17.3332672 0,15.3898926 0,13 C0,10.6101074 1.95438832,8.6667328 4.35738031,8.6667328 C6.7605718,8.6667328 8.71496012,10.6101074 8.71496012,13 C8.71496012,15.3898926 6.7605718,17.3332672 4.35738031,17.3332672 Z M4.35738031,10.2917328 C2.85518614,10.2917328 1.63404255,11.5071106 1.63404255,13 C1.63404255,14.4928894 2.85518614,15.7082672 4.35738031,15.7082672 C5.85977392,15.7082672 7.08091757,14.4928894 7.08091757,13 C7.08091757,11.5071106 5.85977392,10.2917328 4.35738031,10.2917328 Z" id="Shape"></path>
                            <path d="M6.92952128,12.4800873 C6.55033246,12.4800873 6.18211435,12.2839051 5.98164894,11.9329986 C5.68424201,11.4140777 5.86735374,10.7521362 6.38916225,10.4551849 L16.4972074,4.72444152 C17.0190159,4.42649843 17.684641,4.60859679 17.9832447,5.12950136 C18.2806516,5.64842225 18.0975399,6.31036377 17.5757314,6.60731505 L7.46748669,12.3380585 C7.29753988,12.4344635 7.1124335,12.4800873 6.92952128,12.4800873 Z" id="Path"></path>
                            <path d="M17.0365691,21.4175873 C16.8534574,21.4175873 16.6683511,21.3719635 16.4984043,21.2755585 L6.39015957,15.5448151 C5.86835106,15.2490539 5.68543884,14.5871124 5.98284572,14.0670014 C6.27905586,13.5470886 6.94567818,13.3639984 7.46868352,13.6619415 L17.5769282,19.3926849 C18.0987367,19.6884461 18.2816489,20.3503876 17.984242,20.8704986 C17.7827793,21.2214051 17.4145612,21.4175873 17.0365691,21.4175873 L17.0365691,21.4175873 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    `,
    shareDeviceIOS: `
        <?xml version="1.0" encoding="UTF-8"?>
        <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
            <title>846E5DC2-499E-4A07-A986-F330CEE561DE@1x</title>
            <desc>Created with sketchtool.</desc>
            <g id="MOBILE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="home-mobile-v5.2" transform="translate(-238.000000, -596.000000)" fill-rule="nonzero">
                    <g id="Group-36" transform="translate(238.000000, 596.000000)">
                        <g id="linkedin-copy" fill="#0292F2">
                            <circle id="Oval" cx="23.8292389" cy="23.8288142" r="23.8292389"></circle>
                        </g>
                        <g id="share-(3)" transform="translate(13.000000, 10.000000)" fill="#FFFFFF" stroke="#FFFFFF">
                            <path d="M21.0758874,10.1428505 L18.3330535,10.1428505 L18.3330535,11.9714418 L20.1616448,11.9714418 L20.1616448,25.4286046 L1.87589073,25.4286046 L1.87589073,11.9714418 L3.70448206,11.9714418 L3.70448206,10.1428505 L0.961595061,10.1428505 C0.456218834,10.1428505 0.0472993927,10.5517699 0.0472993927,11.0571462 L0.0472993927,26.3428472 C0.0472993927,26.8482234 0.456218834,27.2571429 0.961595061,27.2571429 L21.0758874,27.2571429 C21.5812636,27.2571429 21.9901831,26.8482234 21.9901831,26.3428472 L21.9901831,11.0571462 C21.9901831,10.5517699 21.5812636,10.1428505 21.0758874,10.1428505 Z" id="Path"></path>
                            <polygon id="Path" points="10.1044456 3.4999958 10.1044456 16.4571628 11.9330369 16.4571628 11.9330369 3.4999958 15.8580371 7.42499602 17.1508872 6.13214595 11.0187412 0 4.88659528 6.13214595 6.17944535 7.42499602"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    `
};

export {ICONS};
