import React from 'react';
import PropTypes from 'prop-types';
import {Box, Link} from '@material-ui/core';
import {messages as M} from '../../../messages';
import LogoIM from '../../../assets/logo_im.svg';
import LogoNetgenix from '../../../assets/logo_netgenix.png';
import './style.scss';

function Footer({classes}) {
    return <Box className="footer" classes={classes}>
        <Box className="logo-container">
            <Link target="_blank" href="https://netgenix.com/" className="footer-link">
                <img src={LogoNetgenix} alt="logo Netgenix" className="footer-logo" />
            </Link>
            <Link target="_blank" href="https://ggg.instigatemobile.com/" className="footer-link">
                <img src={LogoIM} alt="logo Instigate Mobile" className="footer-logo" />
            </Link>
        </Box>
        <Box className="footer-content">
            <Link variant="body1" className="footer-links" target="_blank" href="/conditions">
                {M.get('home.conditionsAndPolicy.termsConditions')}
            </Link>
            <Link variant="body1" className="footer-links" target="_blank" href="/policy">
                {M.get('home.conditionsAndPolicy.privacyPolicy')}
            </Link>
            <Link variant="body1" className="footer-links" target="_blank" href="mailto:contact@netgenix.com">
                {M.get('home.conditionsAndPolicy.contactUs')}
            </Link>
        </Box>
    </Box>;
}

export default Footer;

Footer.propTypes = {
    classes: PropTypes.object
};