import React from 'react'
import ReactFBLike from 'react-fb-like';
import {Box} from '@material-ui/core';
import './style.scss'

const areEqual = (prevProps, nextProps) => true;

const LikeButtons = React.memo(props => {
    return (
        <Box className="like-buttons">
            <div>
                <ReactFBLike
                    language="en_US"
                    href="https://www.facebook.com/COVID19Local/"
                    width="100"
                    layout="button_count"
                    action="like"
                    size="large"
                    share={false}
                />
            </div>
            <div className="twitter">
                <a
                    href="https://twitter.com/COVID19local1?ref_src=twsrc%5Etfw"
                    className="twitter-follow-button"
                    data-size="large"
                    data-show-screen-name="false"
                    data-show-count="true"
                >
                    Follow @COVID19local1
                </a>
            </div>
        </Box>
    )
}, areEqual);

export default LikeButtons;