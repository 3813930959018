import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {messages as M} from '../../../messages';

import {Grid, Box, Typography} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, TwitterIcon, LinkedinIcon, FacebookIcon} from 'react-share';
import {Svg} from '../../../view/common/svg';

import {envSettings} from '../../../configs/envSettings';

function Icons({showNotify, showLabel, onNotifyClick, onNativeShareClick, isIOS, direction = 'row', pageID, url = ''}) {

    return (
        <Fragment>
            {showLabel && (
                <Grid container
                    justify="center"
                    item
                    xs={12}
                    className="txt-container"
                >
                    <Typography variant="body2" color="primary" align="center"> {M.get('home.shareText')} </Typography>
                </Grid>
            )}
            <Grid container
                item
                xs={12}
                spacing={2}
                justify="center"
                direction={direction}
                wrap="nowrap"
                className="social-share-buttons-container"
            >
                <Grid item xs="auto">
                    <FacebookShareButton url={`${envSettings.host}${url}`}
                        id={`${envSettings.gaSelectors.shareFacebook}--${pageID}`}
                        className="social-share social-share--icon social-share--facebook google-analytics-button"
                    >
                        <FacebookIcon size={48} round />
                    </FacebookShareButton>
                </Grid>
                {navigator.share && <Grid item xs="auto">
                    <Box className="social-share social-share--icon google-analytics-button"
                        onClick={onNativeShareClick}
                        id={`${envSettings.gaSelectors.shareNative}--${pageID}`}
                    >
                        <span className="hand">
                            {isIOS ? (
                                <Svg name="shareDeviceIOS" className="share-device" />
                            ) : (
                                <Svg name="shareDevice" className="share-device" />
                            )}
                        </span>
                    </Box>
                </Grid>}
                <Grid item xs="auto">
                    <TwitterShareButton
                        url={`${envSettings.host}${url}`}
                        hashtags={['StayHome', 'virus', 'community', 'health', 'covidsymptoms', 'infogears']}
                        title={M.get('socialShare.tweetTitle')}
                        className="social-share social-share--twitter google-analytics-button"
                        id={`${envSettings.gaSelectors.shareTwitter}--${pageID}`}
                    >
                        <TwitterIcon size={48} round />
                    </TwitterShareButton>
                </Grid>
                <Grid item xs="auto">
                    <LinkedinShareButton url={`${envSettings.host}${url}`}
                        className="social-share social-share--icon social-share--linkedin google-analytics-button"
                        id={`${envSettings.gaSelectors.shareLinkedin}--${pageID}`}
                    >
                        <LinkedinIcon size={48} round />
                    </LinkedinShareButton>
                </Grid>
                {showNotify && <Grid item xs="auto">
                    <Box className="social-share social-share--icon social-share--subscribe google-analytics-button"
                        onClick={onNotifyClick}
                        id={`${envSettings.gaSelectors.subscribe}--${pageID}`}
                    >
                        <span className="hand">
                            <NotificationsIcon />
                        </span>
                    </Box>
                </Grid>}
            </Grid>
        </Fragment>
    );
}

export default Icons;

Icons.propTypes = {
    showNotify: PropTypes.bool,
    showLabel: PropTypes.bool,
    isIOS: PropTypes.bool,
    onNotifyClick: PropTypes.func,
    onNativeShareClick: PropTypes.func,
    pageID: PropTypes.string,
    direction: PropTypes.string
};