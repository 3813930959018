import React from 'react';
import PropTypes from 'prop-types';
import {ICONS} from './icons/icons';

class Svg extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        name: PropTypes.string.isRequired
    };

    render() {
        const {name, className} = this.props;

        return (
            <div
                className={className}
                dangerouslySetInnerHTML={{__html: ICONS[name]}}
            />
        );
    }
}

export {Svg};