import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {messages as M} from '../../../messages';
import {envSettings} from '../../../configs/envSettings';

import {Grid, Typography} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, TwitterIcon, LinkedinIcon, FacebookIcon} from 'react-share';
import {Svg} from '../../common/svg';

function Buttons({showNotify, showLabel, onNotifyClick, onNativeShareClick, isIOS, pageID, url = '', size}) {
    return (
        <Fragment>
            {showLabel && <Grid item xs={12} className="txt-container">
            </Grid>}
            <Grid container
                item
                xs={12}
                spacing={2}
                className="social-share-buttons-container"
            >
                <Grid item xs="auto">
                    <FacebookShareButton url={`${envSettings.host}${url}`}
                        id={`${envSettings.gaSelectors.shareFacebook}--${pageID}`}
                        className="social-share social-share--button social-share--facebook google-analytics-button"
                        resetButtonStyle={false}
                    >
                        <FacebookIcon size={size} round />
                        {M.get('socialShare.share')}
                    </FacebookShareButton>
                </Grid>
                {navigator.share && <Grid item xs="auto">
                    <button
                        className="social-share social-share--button social-share--device google-analytics-button"
                        onClick={onNativeShareClick}
                        id={`${envSettings.gaSelectors.shareNative}--${pageID}`}
                    >
                        {isIOS ? (
                            <Svg name="shareDeviceIOS" className="social-share--device__icon" />
                        ) : (
                            <Svg name="shareDevice" className="social-share--device__icon" />
                        )}
                        {M.get('socialShare.share')}
                    </button>
                </Grid>}
                <Grid item xs="auto">
                    <TwitterShareButton
                        url={`${envSettings.host}${url}`}
                        hashtags={['StayHome', 'virus', 'community', 'health', 'covidsymptoms', 'infogears']}
                        title={M.get('socialShare.tweetTitle')}
                        className="social-share social-share--button social-share--twitter google-analytics-button"
                        resetButtonStyle={false}
                        id={`${envSettings.gaSelectors.shareTwitter}--${pageID}`}
                    >
                        <TwitterIcon size={size} round />
                        {M.get('socialShare.tweet')}
                    </TwitterShareButton>
                </Grid>
                <Grid item xs="auto">
                    <LinkedinShareButton url={`${envSettings.host}${url}`}
                        className="social-share social-share--button social-share--linkedin google-analytics-button"
                        resetButtonStyle={false}
                        id={`${envSettings.gaSelectors.shareLinkedin}--${pageID}`}
                    >
                        <LinkedinIcon size={size} round />
                        {M.get('socialShare.share')}
                    </LinkedinShareButton>
                </Grid>
                {showNotify && <Grid item xs="auto">
                    <button id={`${envSettings.gaSelectors.subscribe}--${pageID}`} className="social-share social-share--button social-share--subscribe google-analytics-button" onClick={onNotifyClick}>
                        <NotificationsIcon fontSize="small" />
                        {M.get('socialShare.notify')}
                    </button>
                </Grid>}
            </Grid>
        </Fragment>
    );
}

export default Buttons;

Buttons.propTypes = {
    showNotify: PropTypes.bool,
    showLabel: PropTypes.bool,
    isIOS: PropTypes.bool,
    onNotifyClick: PropTypes.func,
    onNativeShareClick: PropTypes.func,
    pageID: PropTypes.string
};