import React from 'react';
import {
    Box,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Header from '../header';
import {messages as M} from '../../messages';
import Footer from '../common/Footer';
import SocialShareButtons from '../common/SocialShareButtons';
import './style.scss';
import LikeButtons from '../common/LikeButtons';
import assessmentSteps from '../../assets/assessmentSteps.png';
import SEO from "../../../components/seo";


function Home() {
    return (
        <React.Fragment>
            <SEO />
            <Box className="home-section container">
                <Header />
                <Typography className="typography2" color="secondary"> {M.get('home.description.firstNote')} </Typography>
                <img className="assessmentSteps" src={assessmentSteps}/>
                <Box className="about-us">
                    <Typography variant="body2" color="primary" className="about-us-title"> {M.get('home.aboutUs.title')} </Typography>
                    <Typography variant="body1" color="secondary" paragraph>
                        {M.get('home.aboutUs.paragraph1')}
                    </Typography>
                    <Typography variant="body1" color="secondary" paragraph>
                        {M.get('home.aboutUs.paragraph2')}
                    </Typography>
                    <Typography variant="body1" color="secondary" paragraph>
                        {M.get('home.aboutUs.paragraph3')}
                    </Typography>
                    <Typography variant="body1" color="secondary" paragraph>
                        {M.get('home.aboutUs.paragraph4')}
                    </Typography>
                </Box>
                <Box className="social-share-container">
                    <SocialShareButtons pageID="home" />
                    <LikeButtons />
                </Box>
            </Box>
            <Footer/>
        </React.Fragment>
    );
}

export default Home;