import React from 'react';
import PropTypes from 'prop-types';

import {Grid, Hidden} from '@material-ui/core';
import {envSettings} from '../../../configs/envSettings';

import Icons from './Icons';
import Buttons from './Buttons';

import './style.scss';

function SocialShareButtons({showLabel = true, showNotify, onNotifyClick, direction, pageID, url = '', size = 30, changeIconsView = false}) {
    let isIOS = false;
    if (typeof navigator !== `undefined`) {
        isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
            (navigator.platform && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    }
    const onClick = () => {
        const shareData = {
            title: 'COVID-19 Local project needs you! Your community needs you!',
            text: 'Pretty cool local coronavirus website. Take the survey to see your risk and area results.',
            url: `${envSettings.host}${url}`
        };
        try {
            navigator.share(shareData);
            console.log('MDN shared successfully');
        } catch(err) {
            console.log(err);
        }
    };

    return (
        <Grid container
            spacing={2}
            alignItems="center"
            className="social-share-buttons"
        >
            { changeIconsView ? <><Hidden smDown>
                <Buttons
                    pageID={pageID}
                    isIOS={isIOS}
                    showNotify={showNotify}
                    onNotifyClick={onNotifyClick}
                    onNativeShareClick={onClick}
                    showLabel={showLabel}
                    url={url}
                    size={size}
                />
            </Hidden>
            <Hidden mdUp>
                <Icons
                    pageID={pageID}
                    direction={direction}
                    isIOS={isIOS}
                    showNotify={showNotify}
                    onNotifyClick={onNotifyClick}
                    onNativeShareClick={onClick}
                    showLabel={showLabel}
                    url={url}
                />
            </Hidden></> : <><Hidden xsDown>
                <Buttons
                    pageID={pageID}
                    isIOS={isIOS}
                    showNotify={showNotify}
                    onNotifyClick={onNotifyClick}
                    onNativeShareClick={onClick}
                    showLabel={showLabel}
                    url={url}
                    size={size}
                />
            </Hidden>
            <Hidden smUp>
                <Icons
                    pageID={pageID}
                    direction={direction}
                    isIOS={isIOS}
                    showNotify={showNotify}
                    onNotifyClick={onNotifyClick}
                    onNativeShareClick={onClick}
                    showLabel={showLabel}
                />
            </Hidden></>}
        </Grid>
    );
}

export default SocialShareButtons;

SocialShareButtons.propTypes = {
    showLabel: PropTypes.bool,
    showNotify: PropTypes.bool,
    pageID: PropTypes.string,
    direction: PropTypes.string,
    onNotifyClick: PropTypes.func
};